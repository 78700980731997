import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, Router, TitleStrategy } from '@angular/router';
import { Actions, provideEffectsManager } from '@ngneat/effects-ng';
import { devTools } from '@ngneat/elf-devtools';
import * as Sentry from '@sentry/angular';

import {
  IntegratedSearchAuthDataAccessModule,
  IS_AUTH_INTERCEPTOR,
  IS_REFRESH_TOKEN_INTERCEPTOR,
} from '@ess/integrated-search/auth/data-access';
import { IntegratedSearchClientsDataAccessModule } from '@ess/integrated-search/clients/data-access';
import { IntegratedSearchFiltersDataAccessModule } from '@ess/integrated-search/filters/data-access';
import { IntegratedSearchProjectsDataAccessModule } from '@ess/integrated-search/projects/data-access';
import { IntegratedSearchSharedFeatureTitleService } from '@ess/integrated-search/shared/feature';
import { IS_ENVIRONMENT, IS_TOOLTIP_DEFAULT_OPTIONS } from '@ess/integrated-search/shared/utils';
import { IntegratedSearchTacticsDataAccessModule } from '@ess/integrated-search/tactics/data-access/shared';
import { BACKEND_URL, SharedDataAccessApiService } from '@ess/shared/data-access';
import {
  SharedFeatureServicesUserInteractionModule,
  SharedFeatureServicesUserInteractionService,
} from '@ess/shared/feature/services';
import { integratedSearchIcons, SharedUiIconsModule } from '@ess/shared/ui/icons';
import { SHARED_ICON_SIZES } from '@ess/shared/utils/consts';

import { AppTitleStrategy } from './app-title.strategy';
import { ROUTES } from './app.routes';

import { environment } from '../environments/environment';

export function initElfDevTools(actions: Actions) {
  return () => {
    devTools({
      name: 'ISD',
      actionsDispatcher: actions,
    });
  };
}

const authConfig = {
  issuer: environment.envVar.OKTA_ISSUER,
  clientId: environment.envVar.OKTA_CLIENT_ID,
  redirectUri: environment.envVar.OKTA_REDIRECT_URI,
  scopes: ['openid', 'email', 'offline_access'],
};

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: IS_ENVIRONMENT, useValue: environment.envVar.ENV },
    provideRouter(ROUTES),
    provideHttpClient(withInterceptors([IS_AUTH_INTERCEPTOR, IS_REFRESH_TOKEN_INTERCEPTOR])),
    SharedDataAccessApiService,
    SharedFeatureServicesUserInteractionService,
    { provide: BACKEND_URL, useValue: environment.envVar.BACKEND_URL },
    provideEffectsManager(),
    importProvidersFrom(
      BrowserAnimationsModule,
      IntegratedSearchAuthDataAccessModule.forRoot(authConfig),
      IntegratedSearchClientsDataAccessModule.forRoot(),
      IntegratedSearchProjectsDataAccessModule,
      IntegratedSearchFiltersDataAccessModule,
      IntegratedSearchTacticsDataAccessModule,
      SharedFeatureServicesUserInteractionModule,
      SharedUiIconsModule.forRoot({
        sizes: SHARED_ICON_SIZES,
        defaultSize: 'xl',
        icons: integratedSearchIcons,
      }),
    ),
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: IS_TOOLTIP_DEFAULT_OPTIONS },
    IntegratedSearchSharedFeatureTitleService,
    {
      provide: TitleStrategy,
      useClass: AppTitleStrategy,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: environment.envVar.ENV === 'staging' }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initElfDevTools,
      deps: [Actions, Sentry.TraceService],
    },
  ],
};
